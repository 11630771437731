import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/Layout/Layout";
import { navigate } from "gatsby";

import {
  DefaultButton,
  MDTModal,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../styles/StyledComponents";

const UnsubscribePage = () => {
  let searchParams = "";

  if (typeof window !== "undefined") {
    searchParams = window.location.search;
    const parsed = new URLSearchParams(searchParams);
    if (parsed.has("code")) {
      //unsubscribe using querystring code
    } else {
      navigate("/");
    }
  }

  return (
    <Layout vertical={true}>
      <Seo title="Unsubscribe" />
      <ModalWrapper>
        <MDTModal>
          <ModalContent>
            <ModalTitle>
              You've been unsubscribed from the Debris Tracker newsletter.
            </ModalTitle>
            <DefaultButton
              onClick={() => {
                navigate("/");
              }}
            >
              Return Home
            </DefaultButton>
          </ModalContent>
        </MDTModal>
      </ModalWrapper>
    </Layout>
  );
};

export default UnsubscribePage;
